import baseColors from './baseColors';
import { borderRadiusesStrict } from './borderRadius';
import { Theme } from './themeTypes';

export const vostorg: Theme = {
  ...baseColors,
  ...borderRadiusesStrict,

  '--colors-activeBrand': '#AF136F',
  '--colors-hoverBrand': '#E72798',
  '--colors-linkAccented': '#EF6EBA',
  '--colors-linkAccentedHover': '#E72798',
  '--colors-mainBrand': '#EF6EBA',
  '--colors-site': '#404844',

  '--uiColors-AccountAddressesInfo-commentsLineText': 'var(--colors-darkenGrey1)',
  '--uiColors-AccountAddressesInfo-detailsLineText': 'var(--colors-darkenGrey4)',

  '--uiColors-AccountNavigation-activeLinkAfterBackground': 'var(--colors-mainBrand)',
  '--uiColors-AccountNavigation-activeLinkIcon': 'var(--colors-mainBrand)',
  '--uiColors-AccountNavigation-activeLinkText': 'var(--colors-mainBrand)',
  '--uiColors-AccountNavigation-linkHoverAfterBackground': 'var(--colors-mainBrand)',
  '--uiColors-AccountNavigation-linkHoverIcon': 'var(--colors-mainBrand)',
  '--uiColors-AccountNavigation-linkHoverText': 'var(--colors-mainBrand)',

  '--uiColors-AccountNavigationDrop-background': 'var(--colors-secondaryBrandColor)',
  '--uiColors-AccountNavigationDrop-hover': 'rgba(255, 255, 255, 0.25)',

  '--uiColors-AddToListModal-listCheckboxCheckedBackground': 'var(--colors-mainBrand)',
  '--uiColors-AddToListModal-listCheckboxCheckedBorder': 'var(--colors-mainBrand)',
  '--uiColors-AddToListModal-listCheckedBorder': 'var(--colors-mainBrand)',
  '--uiColors-AddToListModal-newListHoverText': 'var(--colors-hoverBrand)',
  '--uiColors-AddToListModal-newListText': 'var(--colors-mainBrand)',

  '--uiColors-AddressModal-loginLinkHoverText': 'var(--colors-site)',
  '--uiColors-AddressModal-loginLinkText': 'var(--colors-mainBrand)',

  '--uiColors-BigProductCard-linkColor': 'var(--colors-mainBrand)',

  '--uiColors-Breadcrumbs-linkHoverText': 'var(--colors-mainBrand)',

  '--uiColors-BulletsNavigation-bulletActiveBackground': 'var(--colors-mainBrand)',

  '--uiColors-Button-accentedHoverText': 'var(--colors-hoverBrand)',
  '--uiColors-Button-accentedText': 'var(--colors-mainBrand)',
  '--uiColors-Button-activeBackground': 'var(--colors-activeBrand)',
  '--uiColors-Button-background': 'var(--colors-mainBrand)',
  '--uiColors-Button-borderedDefaultActiveBackground': 'var(--colors-hoverBrand)',
  '--uiColors-Button-borderedDefaultActiveBorder': 'var(--colors-hoverBrand)',
  '--uiColors-Button-borderedDefaultActiveText': 'var(--colors-white)',
  '--uiColors-Button-borderedDefaultBackground': 'var(--colors-borderedButtonBackground)',
  '--uiColors-Button-borderedDefaultBorder': 'var(--colors-mainBrand)',
  '--uiColors-Button-borderedDefaultHoverBackground': 'var(--colors-mainBrand)',
  '--uiColors-Button-borderedDefaultHoverBorder': 'var(--colors-mainBrand)',
  '--uiColors-Button-borderedDefaultHoverText': 'var(--colors-white)',
  '--uiColors-Button-borderedDefaultText': 'var(--colors-text)',
  '--uiColors-Button-borderedRoundActiveBorder': 'var(--colors-hoverBrand)',
  '--uiColors-Button-borderedRoundBackground': 'var(--colors-transparent)',
  '--uiColors-Button-borderedRoundBorder': 'var(--colors-brandLightGrey1)',
  '--uiColors-Button-borderedRoundDisabledBorder': 'var(--colors-brandLightGrey1)',
  '--uiColors-Button-borderedRoundDisabledText': 'var(--colors-darkGrey)',
  '--uiColors-Button-borderedRoundHoverBorder': 'var(--colors-mainBrand)',
  '--uiColors-Button-defaultDisabledText': 'var(--colors-site)',
  '--uiColors-Button-disabledBackground': 'var(--colors-brandLightGrey1)',
  '--uiColors-Button-hoverBackground': 'var(--colors-hoverBrand)',
  '--uiColors-Button-notAccentedHoverText': 'var(--colors-mainBrand)',
  '--uiColors-Button-notAccentedText': 'var(--colors-site)',
  '--uiColors-Button-text': 'var(--colors-white)',

  '--uiColors-CartButton-background': 'rgba(255, 255, 255, 0.25)',
  '--uiColors-CartButton-badgeBackground': 'var(--colors-mainBrand)',
  '--uiColors-CartButton-badgeText': 'var(--colors-white)',
  '--uiColors-CartButton-hoverBackground': 'rgba(255, 255, 255, 0.1)',
  '--uiColors-CartButton-iconPhone': 'var(--colors-white)',

  '--uiColors-CartPopupDeleteIcon-activeFill': 'var(--colors-darkRed)',
  '--uiColors-CartPopupDeleteIcon-hoverFill': 'var(--colors-red)',

  '--uiColors-CategoriesMenu-shadowBoxBottomActive': 'var(--colors-mainBrand)',
  '--uiColors-CategoriesMenu-toggleBackground': 'rgba(255, 255, 255, 0.25)',
  '--uiColors-CategoriesMenu-toggleHoverBackground': 'rgba(255, 255, 255, 0.1)',

  '--uiColors-CategoriesTree-linkActiveAfter': 'var(--colors-mainBrand)',
  '--uiColors-CategoriesTree-linkActiveHoverAfter': 'var(--colors-mainBrand)',

  '--uiColors-CategoryCard-hoverBefore': 'var(--colors-mainBrand)',
  '--uiColors-CategoryCard-imagePathFill': 'var(--colors-orange)',
  '--uiColors-CategoryCard-moreText': 'var(--colors-mainBrand)',
  '--uiColors-CategoryCard-moreTextHover': 'var(--colors-hoverBrand)',

  '--uiColors-CategoryProductBox-paginationDirectionHoverIcon': 'var(--colors-mainBrand)',
  '--uiColors-CategoryProductBox-paginationItemActiveBackground': 'var(--colors-mainBrand)',
  '--uiColors-CategoryProductBox-paginationItemActiveText': 'var(--colors-white)',
  '--uiColors-CategoryProductBox-paginationItemHoverText': 'var(--colors-mainBrand)',

  '--uiColors-ChatExpiredMessage-phoneText': 'var(--colors-mainBrand)',

  '--uiColors-ChatMessage-pendingSpinnerColor': 'var(--colors-mainBrand)',

  '--uiColors-ChatMessageComposer-nonEmptyMessageButtonIcon': 'var(--colors-mainBrand)',

  '--uiColors-Checkbox-checked': 'var(--colors-mainBrand)',

  '--uiColors-CheckoutCart-countBackgroundColor': 'var(--colors-mainBrand)',
  '--uiColors-CheckoutCart-countColor': 'var(--colors-white)',

  '--uiColors-CheckoutCartFooter-specialValueText': 'var(--colors-mainBrand)',

  '--uiColors-CheckoutCollapsibleHeader-icon': 'var(--colors-mainBrand)',
  '--uiColors-CheckoutCollapsibleHeader-phoneDisabledIcon': 'var(--colors-darkenGrey1)',
  '--uiColors-CheckoutCollapsibleHeader-phoneIcon': 'var(--colors-mainBrand)',

  '--uiColors-CheckoutContactInfo-activeIcon': 'var(--colors-mainBrand)',

  '--uiColors-CheckoutDelivery-desktopTitleIcon': 'var(--colors-mainBrand)',
  '--uiColors-CheckoutDelivery-titleIcon': 'var(--colors-mainBrand)',

  '--uiColors-CheckoutDropdownHeadButton-activeColor': 'var(--colors-mainBrand)',
  '--uiColors-CheckoutDropdownHeadButton-hoverColor': 'var(--colors-hoverBrand)',

  '--uiColors-CheckoutHeader-infoLabelText': 'var(--colors-site)',
  '--uiColors-CheckoutHeader-infoLabelTextHover': 'var(--colors-mainBrand)',

  '--uiColors-CheckoutSideBlock-color': 'var(--colors-mainBrand)',

  '--uiColors-ClarificationBar-mainColor': 'var(--colors-mainBrand)',

  '--uiColors-CollapsePanel-headerHoverText': 'var(--colors-mainBrand)',

  '--uiColors-ContentSorter-controlActiveBorder': 'var(--colors-mainBrand)',
  '--uiColors-ContentSorter-controlActiveHoverBorder': 'var(--colors-hoverBrand)',
  '--uiColors-ContentSorter-controlActiveHoverText': 'var(--colors-hoverBrand)',
  '--uiColors-ContentSorter-controlActiveText': 'var(--colors-mainBrand)',
  '--uiColors-ContentSorter-controlHoverText': 'var(--colors-mainBrand)',

  '--uiColors-Coupon-acceptedValueColor': 'var(--colors-mainBrand)',

  '--uiColors-CreateListFromAddModal-cancelHoverText': 'var(--colors-mainBrand)',

  '--uiColors-FavoritesNavigation-activeItemBorder': 'var(--colors-mainBrand)',
  '--uiColors-FavoritesNavigation-activeItemTitleText': 'var(--colors-mainBrand)',

  '--uiColors-FavoritesRenameAndDelete-buttonRenameHoverText': 'var(--colors-activeBrand)',
  '--uiColors-FavoritesRenameAndDelete-buttonRenameText': 'var(--colors-mainBrand)',

  '--uiColors-FeedbackPopupBody-proceedButtonHoverBackground': 'var(--colors-hoverBrand)',
  '--uiColors-FeedbackPopupBody-proceedButtonTextColor': 'var(--colors-white)',

  '--uiColors-FirstPhone-inHeaderIcon': 'var(--colors-brandLightGrey2)',
  '--uiColors-FirstPhone-inHeaderText': 'var(--colors-site)',
  '--uiColors-FirstPhone-textHover': 'var(--colors-mainBrand)',

  '--uiColors-HeaderBottomLine-background': 'var(--colors-secondaryBrandColor)',
  '--uiColors-HeaderBottomLine-menuLinkHoverBackground': 'rgba(255, 255, 255, 0.25)',

  '--uiColors-HorecaDocuments-HorecaDocumentsLink': 'var(--colors-mainBrand)',

  '--uiColors-LoadingBarIndicator-background': 'var(--colors-mainBrand)',

  '--uiColors-MainSlider-bulletBorder': 'var(--colors-mainBrand)',
  '--uiColors-MainSlider-buttonBackground': 'var(--colors-mainBrand)',

  '--uiColors-MobileMenu-arrowDown': 'var(--colors-mainBrand)',

  '--uiColors-ProductImagesGallery-infoIcon': 'var(--colors-mainBrand)',

  '--uiColors-ProductTile-titleHoverText': 'var(--colors-mainBrand)',

  '--uiColors-PromotionsPage-categoriesTreeTitleHoverText': 'var(--colors-mainBrand)',

  '--uiColors-QuantityBox-buttonActiveBackground': 'var(--colors-hoverBrand)',
  '--uiColors-QuantityBox-buttonActiveBorder': 'var(--colors-hoverBrand)',
  '--uiColors-QuantityBox-buttonBorder': 'var(--colors-mainBrand)',
  '--uiColors-QuantityBox-buttonHoverBackground': 'var(--colors-mainBrand)',
  '--uiColors-QuantityBox-buttonHoverBorder': 'var(--colors-mainBrand)',
  '--uiColors-QuantityBox-buttonPlusBackground': 'var(--colors-mainBrand)',
  '--uiColors-QuantityBox-buttonPlusBackgroundActive': 'var(--colors-activeBrand)',
  '--uiColors-QuantityBox-buttonPlusBackgroundHover': 'var(--colors-hoverBrand)',
  '--uiColors-QuantityBox-deleteButtonHoverIcon': 'var(--colors-white)',
  '--uiColors-QuantityBox-deleteButtonIcon': 'var(--colors-mainBrand)',
  '--uiColors-QuantityBox-minusButtonHoverIconFill': 'var(--colors-white)',
  '--uiColors-QuantityBox-plusButtonIconFill': 'var(--colors-white)',

  '--uiColors-Radio-checkMarkCheckedBeforeBackground': 'var(--colors-mainBrand)',
  '--uiColors-Radio-checkMarkCheckedBeforeBorder': 'var(--colors-mainBrand)',
  '--uiColors-Radio-hoverBackground': 'var(--colors-mainBrand)',


  '--uiColors-RateButton-activeBackground': 'var(--colors-mainBrand)',
  '--uiColors-RateButton-activeHoverText': 'var(--colors-white)',
  '--uiColors-RateButton-activeText': 'var(--colors-white)',
  '--uiColors-RateButton-borderRadius': '15px',

  '--uiColors-ReturnCoupons-copyCouponText': 'var(--colors-mainBrand)',
  '--uiColors-ReturnCoupons-copyCouponTextHover': 'var(--colors-hoverBrand)',

  '--uiColors-SavedAddressItem-borderSelected': 'var(--colors-mainBrand)',

  '--uiColors-ScheduleDelivery-closestTimeAvailableText': 'var(--colors-green)',
  '--uiColors-ScheduleDelivery-closestTimeClosingText': 'var(--colors-attention)',
  '--uiColors-ScheduleDelivery-timerText': 'var(--colors-mainBrand)',

  '--uiColors-SearchPage-errorBulletBorder': 'var(--colors-mainBrand)',

  '--uiColors-ServicePage-bullet': 'var(--colors-mainBrand)',
  '--uiColors-ServicePage-linkText': 'var(--colors-mainBrand)',

  '--uiColors-chatPage-linkText': 'var(--colors-mainBrand)',

  '--uiColors-ShareModal-optionHoverText': 'var(--colors-mainBrand)',

  '--uiColors-StepHeader-iconColor': 'var(--colors-mainBrand)',

  '--uiColors-SwitchLocale-activeLinkText': 'var(--colors-secondaryBrandColor)',
  '--uiColors-SwitchLocale-linkText': 'var(--colors-site)',

  '--uiColors-TabList-activeRoundedTabLabelBackGround': 'var(--colors-mainBrand)',
  '--uiColors-TabList-activeRoundedTabLabelText': 'var(--colors-mainBrand)',
  '--uiColors-TabList-plainActiveTabBottomBorder': 'var(--colors-mainBrand)',

  '--uiColors-ThumbsNavigation-thumbActiveBorder': 'var(--colors-mainBrand)',

  '--uiColors-Tooltip-color': 'var(--colors-mainBrand)',

  '--uiColors-UserAgreements-linkText': 'var(--colors-mainBrand)',

  '--uiColors-WithRadio-checkMarkCheckedBeforeBackground': 'var(--colors-mainBrand)',
  '--uiColors-WithRadio-checkMarkCheckedBeforeBorder': 'var(--colors-mainBrand)',

  '--uiColors-b2bIcon': 'var(--colors-mainBrand)',
  '--uiColors-benefitsColor': 'var(--colors-secondaryBrandColor)',
  '--uiColors-dustyGray': 'var(--colors-darkenGrey1)',

  '--uiColors-globals-RadioButtonGroupDefaultLabelActiveText': 'var(--colors-mainBrand)',
  '--uiColors-globals-RadioButtonGroupDisabledLabelActiveText': 'var(--colors-mainBrand)',
  '--uiColors-globals-bodyBackground': 'var(--colors-lightGrey1)',
  '--uiColors-globals-formControlBorder': 'var(--colors-brandLightGrey2)',
  '--uiColors-globals-formControlErrorText': 'var(--colors-red)',
  '--uiColors-globals-formControlHasErrorBorder': 'var(--colors-red)',
  '--uiColors-globals-formControlHintText': 'var(--colors-brandLightGrey8)',
  '--uiColors-globals-formControlHoverFocusBorder': 'var(--colors-doveGray)',
  '--uiColors-globals-formControlReadOnlyBackground': 'var(--colors-brandLightGrey5)',
  '--uiColors-globals-formControlReadOnlyText': 'var(--colors-darkenGrey1)',

  '--uiColors-mapPinColor': 'var(--colors-mainBrand)',
  '--uiColors-spinner': 'var(--colors-secondaryBrandColor)',
  '--uiColors-FavoriteButton-checkedActivePath': 'var(--colors-mainBrand)',

  '--uiColors-MainWidget-icon': 'var(--colors-mainBrand)',

  '--uiColors-ProgressBarLine': 'var(--uiColors-Button-background)',
};

export default vostorg;
